<template>
  <div class="">
    <h1>notfound</h1>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>